@import "tailwindcss/base";
@import "tailwindcss/components";
@import "tailwindcss/utilities";
@import "react-alice-carousel/lib/alice-carousel.css";
@import "react-notifications-component/dist/theme.css";
@import "swiper/css";
@import "swiper/css/pagination";
@import "@fontsource/noto-sans-jp/japanese-300.css";
@import "@fontsource/noto-sans-jp/japanese-400.css";
@import "@fontsource/noto-sans-jp/japanese-500.css";
@import "@fontsource/noto-sans-jp/japanese-700.css";

body {
  margin: 0;
  font-family: "Noto Sans JP", sans-serif;
  font-optical-sizing: auto;
  font-weight: 400;
  font-style: normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

@layer utilities {
  /* Hide scrollbar for Chrome, Safari and Opera */
  .no-scrollbar::-webkit-scrollbar {
    display: none;
  }
  /* Hide scrollbar for IE, Edge and Firefox */
  .no-scrollbar {
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
    overflow: hidden;
  }
}
